import { useState, type FormEvent } from "react";
import { css, cx } from "@styled-system/css";
import { button } from "@styled-system/recipes";
import Trans from "@/i18n/Trans";
import { useRouter } from "@/router";
import { login } from "@/modules/cloud/user";
import { getRouteBasedOnQuery } from "@/utils/paths";
import useControl from "@/hooks/useControl";
import useI18n from "@/hooks/useI18n";
import Link from "@/components/react/Link";
import Input from "@/components/react/Input";
import PageContainer from "@/components/react/PageContainer";
import ForgotPassword from "./components/ForgotPassword";
import GenericFailure from "@/components/react/GenericFailure";

export default function Login() {
  const { t } = useI18n();
  const router = useRouter();
  const [email, setEmail] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const forgotPasswordDialog = useControl();

  const handleLogin = async (e: FormEvent) => {
    e.preventDefault();
    const code = router.query.code;
    setIsLoading(true);

    const result = await login({ username: email!, password: password!, promoCode: code });

    if (!result?.user) {
      setError(true);
      setIsLoading(false);
      return;
    }

    let promoResult = {};
    if (code && !result?.promoCodeError) promoResult = { prResult: 1 };
    if (code && result?.promoCodeError) promoResult = { prError: 1 };

    const { pathname, query } = await getRouteBasedOnQuery();
    if (pathname === "/webapp") window.open(pathname, "_self");
    else router.push(pathname, { query: { ...query, ...promoResult } });
  };

  return (
    <>
      <PageContainer
        onSubmit={handleLogin}
        image="account.svg"
        title={
          ["purchase", "giftcard"].includes(router.query.from || "")
            ? t("account.login.continuePurchase.heading")
            : t("account.login.heading")
        }
        subtitle={
          <Trans i18nKey="account.login.notRegistered">
            <Link aria-label="sign up" href="/signup" query={router.query} className={css({ color: "primary" })}>
              sign up
            </Link>
          </Trans>
        }
      >
        <Input
          dispatchEventOnLoad
          attributes={{
            autoComplete: "username",
            required: true,
            autoFocus: true,
            type: "email"
          }}
          className={css({
            maxWidth: "22rem",
            mb: "1.5rem"
          })}
          label={t("account.placeholder.email")}
          value={email}
          onInput={(e) => setEmail(e.target.value)}
        />
        <Input
          dispatchEventOnLoad
          attributes={{
            autoComplete: "current-password",
            required: true,
            // minLength: 6,
            type: "password"
          }}
          className={css({
            maxWidth: "22rem",
            mb: "2rem"
          })}
          label={t("account.placeholder.password")}
          value={password}
          onInput={(e) => setPassword(e.target.value)}
        />
        <button
          type="submit"
          disabled={isLoading}
          className={cx(
            button({ variant: "filled" }),
            css({
              maxWidth: "100%",
              width: "22rem",
              mb: "1.5rem"
            })
          )}
        >
          {t("account.button.login")}
        </button>
        <button
          type="button"
          aria-label="forgot password"
          onClick={forgotPasswordDialog.open}
          className={css({ textAlign: "center", color: "primary", mb: "7rem" })}
        >
          {t("account.login.forgotPassword")}
        </button>
      </PageContainer>
      <ForgotPassword onClose={forgotPasswordDialog.close} isOpen={forgotPasswordDialog.isOpen} />
      <GenericFailure
        isOpen={error}
        title={t("account.invalidCredentials.heading")}
        subtitle={t("account.invalidCredentials.text")}
        onClose={() => setError(false)}
      />
    </>
  );
}
