import { getAppValue } from "@/utils/getAppValue";
import SocialLogin from "../SocialLogin";
import LoginView from "./Login";

const loginEnum = {
  camerito: SocialLogin,
  barkio: LoginView,
  bibino: LoginView
};
export const Login = getAppValue(loginEnum);
export default Login;
