import Link from "@/components/react/Link";
import useI18n from "@/hooks/useI18n";
import Trans from "@/i18n/Trans";
import { css } from "@styled-system/css";
import { Flex } from "@styled-system/jsx";
import { links } from "@/modules/environment";

export default function TermsAndPrivacy() {
  const { locale } = useI18n();
  return (
    <Flex alignItems="center" color="highEmphasis">
      <Trans i18nKey="account.signup.tosAndPp">
        <Link href="/terms" aria-label="Terms of services" className={css({ color: "primary", mx: "0.25rem" })}>
          Terms of Services
        </Link>
        <Link
          href={locale === "de" ? links.privacyPolicyDeUrl : links.privacyPolicyUrl}
          locale={false}
          aria-label="Terms of services"
          className={css({ color: "primary", ml: "0.25rem" })}
        >
          Privacy Policy
        </Link>
      </Trans>
    </Flex>
  );
}
