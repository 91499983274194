import { Box } from "@styled-system/jsx";
import { useEffect } from "react";
import useI18n from "@/hooks/useI18n";
import { css, cx } from "@styled-system/css";
import GoogleLogo from "@/assets/common/google-logo.svg?react";

export default function ContinueWithGoogle({
  handleLogin,
  progressing,
  buttonClass
}: {
  handleLogin: (credential: string, type: "google") => void;
  progressing: boolean;
  buttonClass: string;
}) {
  const { t } = useI18n();

  const handleCredentialResponse = (response: { credential: string }) => {
    handleLogin(response.credential, "google");
  };

  useEffect(() => {
    window.google.accounts.id.initialize({
      client_id: "1031862966542-svbuk78gog3tcilttarmvsdblj9b15nm.apps.googleusercontent.com",
      ux_mode: "popup",
      callback: handleCredentialResponse
    });
    const createFakeGoogleWrapper = () => {
      const googleLoginWrapper = document.createElement("div");
      googleLoginWrapper.style.display = "none";
      googleLoginWrapper.classList.add("custom-google-button");

      document.body.appendChild(googleLoginWrapper);

      window.google.accounts.id.renderButton(googleLoginWrapper, {
        type: "icon",
        width: "200"
      });

      const googleLoginWrapperButton = googleLoginWrapper.querySelector("div[role=button]");

      return {
        click: () => {
          googleLoginWrapperButton.click();
        }
      };
    };
    const googleButtonWrapper = createFakeGoogleWrapper();
    const handleGoogleLogin = () => {
      googleButtonWrapper.click();
    };
    const buttonEl = document.getElementById("custom-google-button");

    buttonEl?.addEventListener("click", handleGoogleLogin);
    return () => buttonEl?.removeEventListener("click", handleGoogleLogin);
  }, []);

  return (
    <button className={cx(buttonClass, css({ pos: "relative" }))} disabled={progressing} id="custom-google-button">
      <GoogleLogo />
      {t("continue.google")}
      <Box
        className={css({
          opacity: 0,
          position: "absolute",
          zIndex: 1,
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          borderRadius: "2rem",
          "& div[role='button']": {
            width: "296px!",
            height: "59px!"
          }
        })}
      />
    </button>
  );
}
