import { useState, useRef, useCallback, useEffect } from "react";
import GenericFailure from "@/components/react/GenericFailure";
import Spinner from "@/components/react/Spinner";
import { Router, useRouter } from "@/router";
import navigationProps from "@/utils/navigationProps";
import Dialog from "@/components/react/Dialog";
import { Box, Flex } from "@styled-system/jsx";
import useControl from "@/hooks/useControl";
import { loginWithApple, loginWithGoogle } from "@/modules/cloud/user";
import { UserSession } from "@/modules/user/User";
import useI18n from "@/hooks/useI18n";
import AppImage from "@/components/react/AppImage";
import { css } from "@styled-system/css";
import Wrong from "@/assets/camerito/wrong.svg?react";
import { getRouteBasedOnQuery } from "@/utils/paths";
import { auth } from "./enums";
import EmailIcon from "@/assets/camerito/email.svg?react";
import ContinueWithApple from "./components/ContinueWithApple";
import ContinueWithEmail from "./components/ContinueWithEmail";
import ContinueWithGoogle from "./components/ContinueWithGoogle";

const buttonClass = css({
  py: "1rem",
  bg: "surface",
  border: "1px solid",
  borderColor: "lowEmphasis",
  borderRadius: "100px",
  color: "highEmphasis",
  fontSize: "0.875rem",
  fontWeight: 500,
  letterSpacing: "0.1px",
  lineHeight: "1.25rem",
  display: "flex",
  alignItems: "center",
  columnGap: "0.5rem",
  justifyContent: "center",
  _hover: {
    bg: "lightBackground"
  },
  _active: {
    bg: "lightBackground"
  }
});

type Method = "apple" | "google" | "email";

export default function SocialLogin() {
  const router = useRouter();
  const query = Router.getQuery();
  const { t } = useI18n();
  const genericError = useControl();
  const providerError = useControl();
  const [loginWithEmail, setLoginWithEmail] = useState(Boolean(query?.method));
  const [progressing, setProgressing] = useState(false);
  const role = useRef<null | string>(null);
  const navProps = navigationProps("role-selection");

  const onRouteChange = useCallback(() => {
    const href = window.location.href;
    if (!href.includes("method=")) setLoginWithEmail(false);
    if (href.includes("method=")) setLoginWithEmail(true);
  }, [loginWithEmail]);

  useEffect(() => {
    window.addEventListener("popstate", onRouteChange);
    return () => window.removeEventListener("popstate", onRouteChange);
  }, []);

  useEffect(() => {
    const roleInProps = navProps.get()?.role;
    if (roleInProps) role.current = roleInProps;

    return () => navProps.clear();
  }, [navProps]);

  function continueWithEmail() {
    router.updateQuery({ method: "email" });
    setLoginWithEmail(true);
  }

  async function handleLogin(userData: any, method: Method) {
    try {
      if (!userData && method !== "email") throw Error("no user data");
      setProgressing(true);
      const { pathname } = await getRouteBasedOnQuery();
      const route = role.current === "viewer" ? "/webapp/viewer" : pathname;

      if (method === "google") {
        const res = await loginWithGoogle({ idToken: userData });
        if (res === auth.PROVIDER_LOGIN_ERROR.ALREADY_EXISTS) throw res;
        if (res === auth.PROVIDER_LOGIN_ERROR.DEACTIVATED) throw res;
        await UserSession.syncSession(res.sessionToken);
      }

      if (method === "apple") {
        const res = await loginWithApple({
          authorizationCode: userData.authorizationCode,
          idToken: userData.idToken,
          redirectUrl: window.location.origin + "/api/applesignin"
        });
        if (res === auth.PROVIDER_LOGIN_ERROR.ALREADY_EXISTS) throw res;
        if (res === auth.PROVIDER_LOGIN_ERROR.DEACTIVATED) throw res;
        await UserSession.syncSession(res.sessionToken);
      }

      navProps.clear();
      router.push(route, {
        locale: route.includes("webapp") ? false : true
      });
    } catch (err) {
      if (err === auth.PROVIDER_LOGIN_ERROR.ALREADY_EXISTS) providerError.open();
      else genericError.open();
      setProgressing(false);
    }
  }

  return (
    <Box>
      {!loginWithEmail && (
        <Flex flexDir="column" alignItems="center">
          <AppImage src="small-logo.svg" imgProps={{ width: "80px", height: "80px" }} />
          <h2 className={css({ my: "1rem", textAlign: "center" })}>{t("login.title")}</h2>
          <p className={css({ textAlign: "center" })}>{t("login.txt")}</p>
          <Flex
            className={css({
              mt: "2.5rem",
              mb: ["3rem", null, "5rem"],
              width: "296px",
              flexDirection: "column",
              alignItems: "center",
              rowGap: "1rem",
              "& button": {
                width: "100%"
              }
            })}
          >
            <ContinueWithGoogle progressing={progressing} handleLogin={handleLogin} buttonClass={buttonClass} />
            <ContinueWithApple progressing={progressing} handleLogin={handleLogin} buttonClass={buttonClass} />
            <button className={buttonClass} onClick={continueWithEmail} disabled={progressing}>
              <EmailIcon className={css({ fill: "primary" })} />
              {t("continue.email")}
            </button>
            <Box minH="40px" mt="1rem">
              {progressing && <Spinner size="40" />}
            </Box>
          </Flex>
        </Flex>
      )}
      {loginWithEmail && <ContinueWithEmail handleLogin={handleLogin} />}
      <GenericFailure isOpen={genericError.isOpen} onClose={genericError.close} />
      <Dialog
        key="dialog"
        isOpen={providerError.isOpen}
        onClose={providerError.close}
        title="account.useDifferentMethod.title"
        subtitle="account.useDifferentMethod.subtitle"
        icon={{
          component: Wrong,
          className: css({
            "& path": {
              fill: "warning"
            }
          })
        }}
        buttons={{
          confirm: {
            label: t("account.button.ok"),
            onClick: providerError.close
          }
        }}
      />
    </Box>
  );
}
