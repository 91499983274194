import useControl from "@/hooks/useControl";
import useI18n from "@/hooks/useI18n";
import environment, { apiKeys } from "@/modules/environment";
import { useRef, useState } from "react";
import { login, signup, checkEmailAuthentication } from "@/modules/cloud/user";
import { verifyGoogleToken } from "@/utils/verifyGoogleToken";
import GenericFailure from "@/components/react/GenericFailure";
import { Flex } from "@styled-system/jsx";
import ReCAPTCHA from "react-google-recaptcha";
import AppImage from "@/components/react/AppImage";
import { css, cx } from "@styled-system/css";
import Input from "@/components/react/Input";
import { button } from "@styled-system/recipes";
import { auth, steps } from "../enums";
import { errorStringKeysEnum, formStringKeys } from "@/appContent/login";
import ErrorIcon from "@/assets/common/error.svg?react";
import CameritoForgotPassword from "./CameritoForgotPassword";
import TermsAndPrivacy from "./TermsAndPrivacy";

type AuthError = (typeof auth.EMAIL_AUTH_RESULT)[keyof typeof auth.EMAIL_AUTH_RESULT];
type FormError = "PASSWORDS_MISMATCH" | "NOT_AVAILABLE" | "INVALID_CREDENTIALS" | "SHORT_PASSWORD" | "DEACTIVATED";
type Step = keyof typeof steps;

const { isDev } = environment;

export default function ContinueWithEmail({ handleLogin }: { handleLogin: any }) {
  const ReCAPTCHAComponent = ((ReCAPTCHA as any).default || ReCAPTCHA) as typeof ReCAPTCHA;
  const initialState = {
    email: "",
    password: "",
    repeatedPassword: "",
    isProcessing: false,
    step: steps.enterEmail as Step,
    formErrorType: null as FormError | null
  };

  const { t } = useI18n();
  const genericError = useControl();
  const formError = useControl();
  const forgotPassword = useControl();
  const [state, setState] = useState(initialState);
  const [isCaptchaComplete, setIsCaptchaComplete] = useState(false);
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const { email, password, step, isProcessing, repeatedPassword, formErrorType } = state;

  function prepareStateForRequest() {
    if (isProcessing) return;
    setState({ ...state, isProcessing: true, formErrorType: null });
  }

  function handleError(error?: FormError) {
    setState((prevState) => ({
      ...prevState,
      formErrorType: error || prevState.formErrorType,
      isProcessing: false
    }));

    if (!error) genericError.open();
    else formError.open();
  }

  async function checkEmailAvailability() {
    prepareStateForRequest();
    try {
      const result = (await checkEmailAuthentication({ email })) as AuthError;
      if (result === auth.EMAIL_AUTH_RESULT.NOT_AVAILABLE) {
        handleError("NOT_AVAILABLE");
        return;
      }
      if (result === auth.EMAIL_AUTH_RESULT.DEACTIVATED) {
        handleError("DEACTIVATED");
        return;
      }

      setState({ ...state, isProcessing: false });
      if (result === auth.EMAIL_AUTH_RESULT.LOG_IN) setState({ ...state, step: steps.login, formErrorType: null });
      else if (result === auth.EMAIL_AUTH_RESULT.SIGN_UP)
        setState({ ...state, step: steps.signup, formErrorType: null });
      else handleError();
    } catch (err) {
      handleError();
    }
  }

  function throwErr(message: FormError) {
    throw Error(message);
  }

  async function handleLoginOrSignup(type: "signup" | "login") {
    const Actions = {
      [steps.signup]: () =>
        signup({
          email,
          password,
          // familyName: undefined,
          promoCode: undefined
        } as any),
      [steps.login]: () =>
        login({
          username: email,
          password,
          promoCode: undefined,
          installationId:
            (() => {
              try {
                let installation = window.localStorage.getItem("viewer-installation");
                if (installation) installation = JSON.parse(installation).state.installationId;
                return installation;
              } catch (err) {
                return undefined;
              }
            })() || undefined
        })
    };
    const action = Actions[type];
    try {
      if (!isDev && type === "signup" && recaptchaRef.current) {
        const token = await recaptchaRef.current.executeAsync();
        prepareStateForRequest();
        const passed = await verifyGoogleToken({
          token: token || "",
          isCaptchaComplete
        });
        if (!passed) {
          handleError();
          return;
        }
      }

      if (step === "signup" && password.length < 8) throwErr("SHORT_PASSWORD");
      if ((step === "signup" && !repeatedPassword) || (step === "signup" && password !== repeatedPassword))
        throwErr("PASSWORDS_MISMATCH");

      const response = await action();
      if (!response) throwErr("INVALID_CREDENTIALS");
      handleLogin(null, "email");
    } catch (err) {
      console.log("catch");
      recaptchaRef.current?.reset();
      const errorType = (err as Error)?.message as FormError | undefined;
      if (errorType === "INVALID_CREDENTIALS") handleError("INVALID_CREDENTIALS");
      else if (errorType === "PASSWORDS_MISMATCH") handleError("PASSWORDS_MISMATCH");
      else if (errorType === "SHORT_PASSWORD") handleError("SHORT_PASSWORD");
      else handleError();
    }
  }

  const didEnterEmail = step !== steps.enterEmail;
  const showForgotPassword = step === steps.login;
  const showRepeatedPassword = step === steps.signup;
  const highlightPasswordField =
    formErrorType === "PASSWORDS_MISMATCH" ||
    formErrorType === "INVALID_CREDENTIALS" ||
    formErrorType === "SHORT_PASSWORD";
  const highlightRepeatedPasswordField = formErrorType === "PASSWORDS_MISMATCH";
  const FormActions = {
    [steps.enterEmail]: () => checkEmailAvailability(),
    [steps.signup]: () => handleLoginOrSignup(steps.signup),
    [steps.login]: () => handleLoginOrSignup(steps.login)
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        FormActions[step]();
      }}
    >
      <Flex mb="7rem" flexDir="column" alignItems="center">
        <AppImage src="small-logo.svg" imgProps={{ height: "80px", width: "80px" }} />
        <h2 className={css({ textAlign: "center", my: "1rem" })}>{t(formStringKeys[step].headingKey)}</h2>
        <p className={css({ mb: "2.5rem", color: "highEmphasis" })}>{didEnterEmail ? email : t("login.description")}</p>
        <Flex className={css({ width: "296px", flexDirection: "column", rowGap: "1.5rem" })}>
          {didEnterEmail && (
            <>
              <Input
                attributes={{
                  autoFocus: true,
                  disabled: isProcessing,
                  required: true,
                  autoComplete: "current-password",
                  "aria-label": "password",
                  type: "password",
                  onChange: ({ target }) => setState({ ...state, password: target.value })
                }}
                label={t("account.placeholder.password")!}
                error={highlightPasswordField}
                value={password}
              />

              {showRepeatedPassword && (
                <Input
                  attributes={{
                    disabled: isProcessing,
                    autoComplete: "current-password",
                    "aria-label": "password",
                    type: "password",
                    required: true,

                    onChange: ({ target }) => setState({ ...state, repeatedPassword: target.value })
                  }}
                  label={t("repeatPassword")!}
                  error={highlightRepeatedPasswordField}
                  value={repeatedPassword}
                />
              )}
            </>
          )}
        </Flex>
        {!didEnterEmail && (
          <Input
            attributes={{
              required: true,
              autoFocus: true,
              disabled: isProcessing,
              autoComplete: "username",
              "aria-label": "email",
              type: "email",
              onChange: ({ target }) => setState({ ...state, email: target.value })
            }}
            className={css({ maxW: "296px" })}
            label="Email"
            value={email}
          />
        )}
        <Flex alignItems="center" mt="0.5rem">
          {formErrorType && (
            <>
              <ErrorIcon className={css({ "& path": { fill: "warning" }, mr: "0.25rem", minWidth: "24px" })} />
              <p className={css({ color: "warning" })}>{t(errorStringKeysEnum[formErrorType])}</p>
            </>
          )}
        </Flex>

        <button
          type="submit"
          className={cx(button({ variant: "filled" }), css({ mt: "1rem", mb: showForgotPassword ? "2.5rem" : "2rem" }))}
          disabled={isProcessing}
        >
          {t(formStringKeys[step].buttonKey)}
        </button>

        {showForgotPassword && (
          <button onClick={forgotPassword.open} aria-label="forgot password">
            <p
              className={css({
                textAlign: "center",
                color: "primary"
              })}
            >
              {t("account.login.forgotPassword")}
            </p>
          </button>
        )}
        {showRepeatedPassword && <TermsAndPrivacy />}
      </Flex>
      <CameritoForgotPassword isOpen={forgotPassword.isOpen} onClose={forgotPassword.close} email={email} />
      <GenericFailure isOpen={genericError.isOpen} onClose={genericError.close} />
      <ReCAPTCHAComponent
        ref={recaptchaRef}
        sitekey={apiKeys.googleSiteKey}
        onChange={() => setIsCaptchaComplete(true)}
        size="invisible"
      />
    </form>
  );
}
