import { useRef, useEffect } from "react";
import useI18n from "@/hooks/useI18n";
import environment from "@/modules/environment";
import AppleLogo from "@/assets/common/apple-logo.svg?react";
import { css } from "@styled-system/css";

export default function ContinueWithApple({
  handleLogin,
  progressing,
  buttonClass
}: {
  handleLogin: (obj: { idToken: string; authorizationCode: string }, type: "apple") => void;
  progressing: boolean;
  buttonClass: string;
}) {
  const didInit = useRef(false);
  const { t } = useI18n();

  useEffect(() => {
    if (!window.AppleID) return;
    if (didInit.current) return;
    didInit.current = true;

    window.AppleID.auth.init({
      clientId: "com.tappytaps.camerito.applesignin",
      scope: "email",
      redirectURI: environment.isDev
        ? "https://test.camerito.com/api/applesignin"
        : "https://camerito.com/api/applesignin",
      usePopup: true
    });
  }, []);

  async function signin() {
    try {
      const data = await window.AppleID.auth.signIn();
      const { code, id_token } = data.authorization;
      handleLogin(
        {
          idToken: id_token,
          authorizationCode: code
        },
        "apple"
      );
    } catch (error) {
      console.log("err: ", error);
      // Handle error.
    }
  }

  return (
    <button className={buttonClass} onClick={signin} disabled={progressing}>
      <AppleLogo className={css({ fill: "highEmphasis" })} />
      {t("continue.apple")}
    </button>
  );
}
