export const errorStringKeysEnum = {
  NOT_AVAILABLE: "account.signup.alreadyTaken",
  INVALID_CREDENTIALS: "invalidPassword",
  PASSWORDS_MISMATCH: "passwordsNotMatch",
  SHORT_PASSWORD: "account.password.tooShort",
  DEACTIVATED: "account.deleted"
} as const;

export const formStringKeys = {
  enterEmail: { buttonKey: "continue", headingKey: "enterEmail" },
  signup: {
    buttonKey: "account.button.createAccount",
    headingKey: "createCameritoAccount"
  },
  login: {
    buttonKey: "account.button.login",
    headingKey: "logToCamerito"
  }
} as const;
