import { useState, useEffect } from "react";
import useI18n from "@/hooks/useI18n";
import { resetPassword } from "@/modules/cloud/user";
import Input from "@/components/react/Input";
import { css } from "@styled-system/css";
import FormDialog from "@/components/react/FormDialog";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export default function ForgotPassword({ isOpen, onClose }: Props) {
  const [email, setEmail] = useState<string | null>(null);
  const [emailSent, setEmailSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useI18n();

  useEffect(() => {
    if (isOpen) {
      setEmail(null);
      setEmailSent(false);
      setIsLoading(false);
    }
  }, [isOpen]);

  async function handleSubmit() {
    setIsLoading(true);
    await resetPassword({ email: email! });
    setEmailSent(true);
    setIsLoading(false);
  }

  const title = emailSent ? "account.login.resetPasswordHeading" : "account.login.text.resetPassword";
  const subtitle = emailSent ? "account.login.resetPasswordSubtitle" : "account.changePassword.text";
  return (
    <FormDialog
      isOpen={isOpen}
      onClose={onClose}
      title={t(title)}
      subtitle={t(subtitle)}
      isProcessing={isLoading}
      onSubmit={emailSent ? onClose : handleSubmit}
      buttons={{
        cancel: !emailSent ? { label: t("account.button.cancel") } : undefined,
        confirm: { label: t("account.button.ok") }
      }}
    >
      {!emailSent && (
        <Input
          style={{ bg: "surface" }}
          className={css({ mt: "24px" })}
          attributes={{
            required: true,
            type: "email",
            disabled: isLoading
          }}
          value={email}
          onInput={({ target }) => setEmail((target as HTMLInputElement).value)}
          label={t("account.placeholder.email")}
        />
      )}
    </FormDialog>
  );
}
