import Dialog from "@/components/react/Dialog";
import { useState, useEffect } from "react";
import { resetPassword } from "@/modules/cloud/user";
import Check from "@/assets/common/check.svg?react";
import Lock from "@/assets/camerito/lock.svg?react";
import useI18n from "@/hooks/useI18n";

export default function CameritoForgotPassword({
  isOpen,
  onClose,
  email
}: {
  isOpen: boolean;
  onClose: () => void;
  email: string;
}) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const { t } = useI18n();

  const handleForget = async () => {
    setIsProcessing(true);
    const result = await resetPassword({ email });
    if (!result) {
      onClose();
      return;
    }
    setEmailSent(true);
    setIsProcessing(false);
  };

  useEffect(() => {
    if (isOpen) setEmailSent(false);
  }, [isOpen]);

  return (
    <Dialog
      title={emailSent ? "account.login.resetPasswordHeading" : "account.login.forgotPassword"}
      icon={{ component: emailSent ? Check : Lock }}
      subtitle={emailSent ? "account.login.resetPasswordSubtitle" : "resetInstructions.text"}
      buttons={{
        confirm: {
          label: emailSent ? t("account.button.ok") : t("account.login.text.resetPassword"),
          onClick: emailSent ? onClose : handleForget
        },
        ...(!emailSent && {
          cancel: {
            label: t("account.button.cancel")
          }
        })
      }}
      isOpen={isOpen}
      onClose={onClose}
      isProcessing={isProcessing}
    />
  );
}
