export const auth = {
  SIGN_UP_ERROR: {
    ALREADY_EXISTS: "Account already exists for this username."
  },
  EMAIL_AUTH_RESULT: {
    LOG_IN: "LOG_IN",
    SIGN_UP: "SIGN_UP",
    NOT_AVAILABLE: "NOT_AVAILABLE",
    DEACTIVATED: "User account has been deactivated"
  },
  PROVIDER_LOGIN_ERROR: {
    ALREADY_EXISTS: "Email already taken",
    DEACTIVATED: "User account has been deactivated"
  }
} as const;

export const steps = {
  enterEmail: "enterEmail",
  signup: "signup",
  login: "login"
} as const;
